<template>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th width="10%" style="text-align:left;" scope="col"></th>
          <th width="10%" scope="col"></th>
          <th width="40%" scope="col">Player</th>
          <th width="10%" class="stats" scope="col">L</th>
          <th width="10%" class="stats" scope="col">S</th>
          <th width="10%" class="stats" scope="col">T</th>
          <th width="10%" style="text-align:center;" scope="col">P</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="profile in leaderboard" :key="profile.id" @click="lookup_profile(profile.gambler.id)">
          <td style="vertical-align: middle; text-align: center;">
            <span v-if="profile.scorecards.at(-1).position === 0" style="color: #595959;">-</span>
            <span v-else-if="profile.scorecards.at(-1).position === 1">&#129351;</span>
            <span v-else-if="profile.scorecards.at(-1).position === 2">&#129352;</span>
            <span v-else-if="profile.scorecards.at(-1).position === 3">&#129353;</span>
            <span v-else-if="profile.scorecards.at(-1).position === (leaderboard.length)" class="wave">&#9995;</span>
            <span v-else style="color: #595959;">{{ profile.scorecards.at(-1).position }}</span>
          </td>

          <td style="vertical-align: middle;">
            <img v-if="profile.gambler.picture"
              v-bind:src="'/server/1900eab6c028483d7126599ee6f50de0d27907b5c65fa90524580b4b0f9852b/' + profile.gambler.picture"
              class="picture" :style="{ border: '2px solid' + profile.gambler.color }" />
          </td>
          <td scope="row">

            <span :style="{ color: profile.gambler.color }">{{ profile.gambler.user.firstName }}</span>
            <br>

            <div style="font-size: 13px;">
              <span v-if="profile.scorecards.length > 1 && profile.scorecards.at(-1).position > 0">
                <span v-if="(profile.scorecards.at(-2).position - profile.scorecards.at(-1).position) > 0"
                  style="color: #57F287;">
                  &#8593;{{ profile.scorecards.at(-2).position - profile.scorecards.at(-1).position }}
                </span>
                <span v-if="(profile.scorecards.at(-2).position - profile.scorecards.at(-1).position) < 0"
                  style="color: #ED4245;">
                  &#8595;{{ (profile.scorecards.at(-2).position - profile.scorecards.at(-1).position) * -1 }}
                </span>

              </span>
              <span style="color:#595959;"> @{{ profile.gambler.user.username }}</span>
            </div>


          </td>
          <td class="stats" style=" padding: .2rem;">
            <span v-if="points">
              {{ profile.line }}
            </span>
            <span v-if="percent">
              {{ ((profile.line / uptoResults) * 100).toFixed(0) }}%
            </span>
            <span v-if="money">
              -
            </span>

            <br>

            <span v-if="points" class="percentages">
              <span v-if="profile.scorecards.at(-1).line === 0">-</span>
              <span v-else>{{ profile.scorecards.at(-1).line }}</span>
            </span>
            <span v-if="percent" class="percentages">
              <span v-if="profile.scorecards.at(-1).line === 0">-</span>
              <span v-else>{{ (profile.scorecards.at(-1).line / weekResults) * 100 }}%</span>
            </span>
          </td>

          <td class="stats" style=" padding: .2rem;">
            <span v-if="points">
              {{ profile.spread }}
            </span>
            <span v-if="percent">
              {{ ((profile.spread / uptoResults) * 100).toFixed(0) }}%
            </span>
            <span v-if="money">
              -
            </span>

            <br>

            <span v-if="points" class="percentages">
              <span v-if="profile.scorecards.at(-1).spread === 0">-</span>
              <span v-else>{{ profile.scorecards.at(-1).spread }}</span>
            </span>
            <span v-if="percent" class="percentages">
              <span v-if="profile.scorecards.at(-1).spread === 0">-</span>
              <span v-else>{{ (profile.scorecards.at(-1).spread / weekResults) * 100 }}%</span>
            </span>
          </td>

          <td class="stats" style=" padding: .2rem;">
            <span v-if="points">
              {{ profile.total }}
            </span>
            <span v-if="percent">
              {{ ((profile.total / uptoResults) * 100).toFixed(0) }}%
            </span>
            <span v-if="money">
              -
            </span>

            <br>

            <span v-if="points" class="percentages">
              <span v-if="profile.scorecards.at(-1).total === 0">-</span>
              <span v-else>{{ profile.scorecards.at(-1).total }}</span>
            </span>
            <span v-if="percent" class="percentages">
              <span v-if="profile.scorecards.at(-1).total === 0">-</span>
              <span v-else>{{ (profile.scorecards.at(-1).total / weekResults) * 100 }}%</span>
            </span>
          </td>

          <td class="stats" style="text-align:center;  padding: .2rem;">
            <span v-if="points">
              <strong>{{ profile.points }}</strong>
            </span>
            <span v-if="percent">
              {{ ((profile.points / (uptoResults * 3)) * 100).toFixed(0) }}%
            </span>
            <span v-if="money">
              -
            </span>

            <br>

            <span v-if="points" class="percentages">
              <span v-if="profile.scorecards.at(-1).points === 0">-</span>
              <span v-else>{{ profile.scorecards.at(-1).points }}</span>
            </span>
            <span v-if="percent" class="percentages">
              <span v-if="profile.scorecards.at(-1).points === 0">-</span>
              <span v-else>{{ ((profile.scorecards.at(-1).points / (weekResults * 3)) * 100).toFixed(0) }}%</span>
            </span>
            <span v-if="money" style="font-size: 12px;">
              <span v-if='profile.bank < 0' style="color:#ED4245;">
                -${{ (profile.bank * -1).toFixed(2) }}
              </span>
              <span v-else style="color:#57F287;">
                ${{ (profile.bank).toFixed(2) }}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
export default {
  name: "Leaderboard",
  props: {
    leaderboard: {
      type: Array,
      required: true
    },
    uptoResults: {
      type: Number,
      required: true
    },
    weekResults: {
      type: Number,
      required: true
    },
    points: {
      type: Boolean,
      required: true
    },
    percent: {
      type: Boolean,
      required: true
    },
    money: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  methods: {
    lookup_profile(id) {
      console.log(id)
      this.$router.push({ name: "Profile", params: { id: id }, });
    }
  }
};
</script>

<style scoped>
.table th {
  border: 0px solid #444;
}

.picture {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.stats {
  text-align: center;
  font-size: 15px;
}

.percentages {
  color: #595959;
  font-size: 11px;
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(18.0deg)
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-12.0deg)
  }

  30% {
    transform: rotate(18.0deg)
  }

  40% {
    transform: rotate(-8.0deg)
  }

  50% {
    transform: rotate(12.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}
</style>