import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    leaderboard: {
      type: Array,
      required: true
    },
    weekResults: {
      type: Number,
      required: true
    }
  },
  mounted() {
    const weeks = this.leaderboard[0].scorecards.map(scorecard => scorecard.week.name);

    const datasets = this.leaderboard.map(scoreboard => {
      return {
        label: scoreboard.gambler.user.firstName,
        data: scoreboard.scorecards.map(scorecard => scorecard.position),
        backgroundColor: scoreboard.gambler.color,
        fill: false,
        borderColor: scoreboard.gambler.color,
        lineTension: 0.3
      };
    });

    if (this.weekResults === 0){
      weeks.pop()
      datasets.pop()
    }

    this.renderChart(
      {
        labels: weeks,
        datasets: datasets
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                reverse: true 
              },
              scaleLabel: {
                display: true, // Set to true to display the label
                labelString: 'Placement', // Your label for the Y-axis
                fontSize: 12, // Customize font size
                fontColor: '#666' // Customize font color
              }
            }
          ]
        },
        annotation: {
          annotations: [
            {
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: "3",
              borderColor: "tomato",
              borderWidth: 1
            }
          ],
          drawTime: "afterDraw" // (default)
        }
      }
    );
  }
};