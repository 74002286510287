<template>
  <div>
    <b-form-select class="selector" v-model="newyear" :options="year" size="sm" disabled></b-form-select>

    <b-form-group>
      <div class="d-flex justify-content-between align-items-center">
        <!-- Select box takes up the remaining space on the left -->
        <b-form-select v-model="week" :options="weeks" @change=updateWeek class="selector-week flex-grow-1 mr-3">
        </b-form-select>

        <!-- Individual buttons aligned to the right -->
        <div class="d-flex">
          <button class="btn btn-custom mr-2" :class="{ 'active-btn': points }" @click="boardFilter('points')">
            P
          </button>
          <button class="btn btn-custom mr-2" :class="{ 'active-btn': percent }" @click="boardFilter('percent')">
            %
          </button>
          <button class="btn btn-custom mr-2" :class="{ 'active-btn': money }" @click="boardFilter('money')">
            $
          </button>
          <button class="btn btn-custom" :class="{ 'active-btn': graph }" @click="graph = !graph">
            Graph
          </button>
        </div>
      </div>
    </b-form-group>

    <div v-if="!loading">
      <div v-if="!graph">
        <leaderboard :leaderboard="leaderboard" :uptoResults="uptoResults ? uptoResults : 0"
          :weekResults="weekResults ? weekResults : 0" :points="points" :percent="percent" :money="money" />
      </div>
      <div v-else>
        <progress-graph :leaderboard="leaderboard" :weekResults="weekResults ? weekResults : 0" />
      </div>
      <div v-else>
        <div class="text-center">
          <b-spinner variant="danger" label="Loading..."></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";

import ProgressGraph from "@/components/graphs/ProgressGraph";
import Leaderboard from "@/components/Leaderboard";

export default {
  name: "Dashboard",
  components: {
    ProgressGraph,
    Leaderboard
  },
  data() {
    return {
      graph: false,

      labels: null,
      data: null,
      loading: true,
      newyear: null,
      year: [{ value: null, text: "2024 CFB Season" }],
      weeks: [],
      week: null,
      newweek: null,

      graph: false,
      points: true,
      percent: false,
      money: false,

      leaderboard: null,
      uptoResults: null,
      weekResults: null
    };
  },
  methods: {
    updateWeek: function () {
      this.newweek = this.week
    },
    boardFilter(filterValue) {
      if (filterValue === 'points') {
        this.points = true;
        this.percent = false;
        this.money = false;
      } else if (filterValue === 'percent') {
        this.percent = true;
        this.points = false;
        this.money = false;
      } else if (filterValue === 'money') {
        this.money = true;
        this.points = false;
        this.percent = false;
      }
    }
  },
  watch: {
    week: function (newVal, oldVal) {
      let self = this;
      api(
        `query($weekId: Int) { scoreboards(weekId:$weekId) { gambler { id picture color user { firstName lastName username } } line spread total points bank scorecards(weekId:$weekId) { week { name } position line spread total points bank } } weekResultCount(weekId:$weekId) resultCount(weekId:$weekId) }`, { "weekId": newVal }
      ).then(data => {
        self.leaderboard = data.scoreboards
        self.uptoResults = data.resultCount
        self.weekResults = data.weekResultCount
        self.loading = false;
      });
    }
  },
  created() {
    let self = this;
    api(`query { playableWeek { id name } }`).then(data => {
      data.playableWeek.forEach((element) => {
        self.weeks.push({
          value: element.id,
          text: element.name,
        });
      });
      self.week = data.playableWeek[0].id
    });
    api(
      `query($weekId: Int) { scoreboards(weekId:$weekId) { gambler { id picture color user { firstName lastName username } } line spread total points bank scorecards(weekId:$weekId) { week { name } position line spread total points bank } } weekResultCount(weekId:$weekId) resultCount(weekId:$weekId) }`
    ).then(data => {
      self.leaderboard = data.scoreboards
      self.uptoResults = data.resultCount
      self.weekResults = data.weekResultCount
      self.loading = false;
    });
  }
};
</script>

<style scoped>
.btn-custom {
  border: 1px solid #595959;
}

.active-btn {
  border: 1px solid #57F287;
}

.selector {
  background-color: #222;
  border-color: #303030;
  color: white;
  font-size: larger;
  margin-bottom: 10px;
}

.selector-week {
  background-color: #222;
  border-color: #303030;
  color: white;
}
</style>